<template>
  <div>
    <modal :show.sync="modalMap" :advancedSettings.sync="advancedSettings" type="custom-map" :scrollToBottom="false" v-on:hideModal="$emit('closeMapModal')">
      <h4 slot="header" class="title title-up"></h4>
      <template slot="close-button">
        <button type="button" class="close" @click="$emit('closeMapModal')" data-dismiss="modal" aria-label="Close"><i class="tim-icons icon-simple-remove"></i></button>
      </template>
      <div class="map-content">
        <help-map></help-map>
        <div id="viewDiv"></div>
        <div class="select-entrave">
          <h4 class="text-center" v-html='entrave.label'></h4>
        </div>
      </div>
      <template slot="footer">
          <!--<base-button type="secondary" @click="$emit('closeMapModal')">Fermer</base-button>-->
          <div>
            <base-switch v-model="advancedSettings" on-text="" off-text=""></base-switch>
            <span> {{ $t('mapPage.advancedSettings') }}</span>
          </div>
          <div>
            <!--<base-button type="danger" class="btn-margin-right" @click="test()" >Test</base-button>-->
            <base-button type="danger" class="btn-margin-right" @click="clearSelect()" >{{ $t('mapPage.btnReset') }}</base-button>
            <base-button type="primary" @click="$emit('saveMapModal', entrave)" >{{ $t('mapPage.btnSave') }}</base-button>
          </div>
      </template>
      <template slot="filters">
        <advanced-filters v-bind:conflictsBuffer="conflictsBuffer" v-bind:mapLoad="mapLoad" v-bind:layer="layer" v-bind:loading="loading" v-bind:date="date" v-bind:ProjectsConflict="ProjectsConflict"
                          v-on:getAdvancedProjects="getAdvancedProjects($event)"
                          v-on:closeAdvancedTab="closeAdvancedTab()"
                          v-on:onChangeLayer="onChangeLayer($event)"
                          v-on:resetAdvancedProjects="resetAdvancedProjects($event)"></advanced-filters>
      </template>
      </modal>
  </div>
</template>
<script>
import {Modal} from 'src/components';
import { loadModules } from 'esri-loader';
import {BaseSwitch} from 'src/components/index';

import HelpMap from './HelpMap.vue';
import AdvancedFilters from './ModalMapAdvancedFilters.vue';
import LoadProjects from './../shared/MapLoadProjects.js';
import RoadNetwork from './../shared/roadNetwork';
import Features from '../shared/features';

export default {
  name: 'modal-map-component',
  components: {
    Modal,
    BaseSwitch,
    HelpMap,
    AdvancedFilters
  },
  props: ['modalMap', 'selectedEntrave', 'uuid', 'date', 'perimeter', 'mapMode','layer'],
  data () {
    return {
      selectMode : "segment",
      loading: false,
      modeFilter: false,
      advancedSettings: false,
      mapLoad: false,
      projects: [],
      projectsLayer: null,
      entrave: {
        name: '',
        label: '',
        value:'',
        selected: {
          path : [],
          startPoint : null,
          endPoint   : null,
          streetsTrack : []
        },
        type: 'linear'
      },
      instance: null,
      map: null,
      view: null,
      drawLayer: null,
      downKeys : {
        isDown  : false,
        control : false,
        char    : ''
      },
      layerStreets: null,
      searchLayer : null,
      busQbcLayer : null,
      possibleSegments: [],
      
      /*selectedSegments: {
        path : [],
        startPoint : null,
        endPoint   : null,
        streetsTrack : []
      },*/
      mode:0,
      FeatureLayer : null,
      geometryEngine: null,
      geodesicUtils : null,
      webMercatorUtils : null,
      spatialReference:null,
      viewArmPMEventHdlr:null,
      Graphic:null,
      SimpleLineSymbol:null,
      Polyline:null,
      Point : null,
      Field : null,
      layerView:null,
      Extent: null,
      hovered: [],
      drawColors: {
        drawing : '#00d6b4',
        selected: '#1f8ef1',
        search  : '#97f0b9'
      },
      distanceUnit : "meters",
      selectionDirectionArrowMarker : null,
      selectionStartMark : null,
      selectionEndMark   : null,
      pointSelectMark    : null,
      pointerPosMark : null,
      entravesList : [],
      conflictsRadius : 10, //default 10 units conflict radius
      conflictsUnits : "meters",
      ProjectsConflict: [],
      conflictsBuffer : null,
      conflictsGraph: null,
      searchBuffer : null,
      searchGraph  : null,
      state : '',
      features: [],
    };
  },
  created() {
    this.instance = JSON.parse(window.localStorage.getItem('instance'));
    this.state = window.localStorage.getItem('state') || "";
    this.features = Features[this.instance.city];
  },
  watch: {
    modalMap(val) {
      if (val) { this.loadMap(); this.advancedSettings=true; } else { this.resetModel(); }
    },
    advancedSettings(val) {
      if (!val) {
        let filters = {
          publish: true,
          nopublish: false,
          ranges: this.date,
          entrave:true,
          closing: false,
          detour: false
        };
        this.getAdvancedProjects([filters, 'project']);
      }
    }
  },
  methods: {
    closeModal() {
      $emit('closeMapModal');
    },
    resetModel () {
      this.selectMode = "segment";
      this.entrave = {
        label: '',
        value: '',
        selected : {
          path : [],
          startPoint : null,
          endPoint   : null,
          streetsTrack : []
        },
        type: 'linear'
      };
      this.distanceUnit = "kilometers";
      this.map=null;
      this.view=null;
      this.drawLayer=null;
      this.projectsLayer=null;
      this.downKeys = {
        isDown  : false,
        control : false,
        char    : ''
      };
      this.layerStreets=null;
      this.searchLayer= null;
      this.busQbcLayer=null;
      this.possibleSegments=[];
      
      /*this.selectedSegments={
        path : [],
        startPoint : null,
        endPoint   : null,
        streetsTrack : []
      };*/
      this.mode=0;
      
      //this.advancedSettings=false;
      this.mapLoad=false;
      this.modeFilter=false;
      this.loading=false;
      
      this.FeatureLayer = null;
      this.geometryEngine=null;
      this.geodesicUtils=null;
      this.webMercatorUtils=null;
      this.spatialReference=null;
      this.viewArmPMEventHdlr=null;
      this.Graphic=null;
      this.SimpleLineSymbol=null;
      this.Polyline=null;
      this.Point=null;
      this.Field=null;
      this.layerView=null;
      this.Extent=null;
      this.hovered=[];
      this.entravesList=[];
      this.conflictsBuffer = null;
      this.conflictsGraph  = null;
      this.searchBuffer = null;
      this.searchGraph = null;
    },
    clearSelect() {
      this.resetModel();
      this.loadMap();
    },
    reverseSelect() {
      this.reversePath();
    },
    initLoad(Map, BaseMap, MapView, FeatureLayer, SimpleLineSymbol, GraphicsLayer, geodesicUtils, Field, Polyline, Point, Graphic, geometryEngine, webMercatorUtils, spatialReference, Extent,VectorTileLayer) {
      
      let user = JSON.parse(window.localStorage.getItem('user'));
      let location = [user.location.longitude, user.location.latitude];
      if(RoadNetwork[this.instance.city].isMultiCity){
        location = [RoadNetwork[this.instance.city].city[this.state].location[0],RoadNetwork[this.instance.city].city[this.state].location[1]];
      }
      //let type = (user.appsettings && user.appsettings.map && user.appsettings.map.typeMap) || 'osm';
      var basemap;
      if (user.appsettings.map && user.appsettings.map.modeMap && user.appsettings.map.modeMap==="1") {
        basemap = new BaseMap({
          portalItem: {id: user.appsettings.map.typeMap}
        });
      }
      else if (user.appsettings.map && user.appsettings.map.modeMap && user.appsettings.map.modeMap==="2"){
        basemap = new BaseMap({
          baseLayers: [
            new VectorTileLayer({
              portalItem: {
                id: user.appsettings.map.typeMap
              }
            })
          ]
        });
      } 
      else {
        basemap = (user.appsettings.map && user.appsettings.map.typeMap);
      }
      
      this.map = new Map({
        basemap: basemap
      });
      
      this.view = new MapView({
          container: "viewDiv",
          map: this.map,
          center: location,
          zoom: 17,
          spatialReference: 102100,
          highlightOptions: {
            color: [255, 255, 255, 0],
            haloOpacity: 0,
            fillOpacity: 0
          }
      });
      
      this.layerStreets = new FeatureLayer({
        url : (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].url:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].url:RoadNetwork[this.instance.city].url),
        visible : true,
        //opacity : 0.5,
        /*renderer : {
          type : "simple",
          symbol : {
            type : "simle-line",
            width : "10px",
            //color : "#ffffff"
            color : "#146317"
          }
        },*/
        outFields:(RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].fields : ((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].fields:RoadNetwork[this.instance.city].fields) 
      });
      
      this.busQbcLayer = new FeatureLayer({
        //url : "https://services9.arcgis.com/A9g6i2NCgdRS8T0v/arcgis/rest/services/Montreal_Transport/FeatureServer/1?token=onoojht7q4gyGfJPa-F93OluZAaX8rfPN1M7Nq89xgwlHRgN2HpVm1mW3nlsH511AWYUNmk7jbfjqkBy94ULKJdMW9l5hi1OKagx62Lbb4cCMyexwlP6jgQkhqCZgSLNjr0gz0rvISW_qeEnCSAxjPPEWtab74JTD8w_dyLx09E_95CrVJ0_8zAo8xZwAd4DJQtaGdNRfsOhEGQ5dHpSjFyyo3XZAmwTqcnhT2dsCjJIFQP_B7IxScWv30RQoMaP",
        url : "https://services9.arcgis.com/A9g6i2NCgdRS8T0v/arcgis/rest/services/Ville_Quebec_Transports/FeatureServer/1",
        visible : false
      });

      this.FeatureLayer = FeatureLayer;
      this.drawLayer = new GraphicsLayer();
      this.projectsLayer = new GraphicsLayer();
      this.Graphic=Graphic;
      this.SimpleLineSymbol = SimpleLineSymbol;
      this.Polyline=Polyline;
      this.Point=Point;
      this.Field=Field;
      this.geometryEngine=geometryEngine;
      this.geodesicUtils=geodesicUtils;
      this.webMercatorUtils=webMercatorUtils;
      this.spatialReference=spatialReference;
      this.Extent=Extent;
      this.map.add(this.layerStreets);
      this.map.add(this.projectsLayer);
      this.map.add(this.drawLayer);
      this.map.add(this.busQbcLayer);
      this.entravesList = [];
      this.conflictsBuffer = null;
      this.searchBuffer = null;
      this.searchGraph  = null;
    },
    fillSearchLayer(callback){
      let _this = this;
      let features = {};
      let id = 1;
      var streetsQuery = _this.layerStreets.createQuery();
      //var spatialRef = _this.layerStreets.spatialReference;
      let size = 5000, from = 0;

      //_this.layerStreets.source.forEach(function(graph){
      var responseFun = function(response){
        if (response.features && response.features.length > 0){
          response.features.forEach(function(graph){
            var sname;
            sname = _this.streetName(graph);
            if (!features[sname]){
              features[sname]=
                {
                  id : id,
                  street_name : sname,
                  gset : [graph.geometry],
                  type : graph.attributes.TYPE,
                  generique : graph.attributes.GENERIQUE,
                  liaison: graph.attributes.LIAISON,
                  specifique : graph.attributes.SPECIFIQUE,
                  direction : graph.attributes.DIRECTION,
                  nom_topographique : graph.attributes.NOM_TOPOGRAPHIE,
                  cote : graph.attributes.COTE,
                  orientation : graph.attributes.ORIENTATION
                };
              id++;
            }else{
              features[sname].gset.push(graph.geometry);
            }
          });
          from = from + size;
          streetsQuery.start = from;
          streetsQuery.returnGeometry=true;
          _this.layerStreets.queryFeatures(streetsQuery).then(responseFun);
        }else{
          var source = Object.keys(features).map(function(sname){
            let feature = features[sname];
            /*var polyline = new _this.Polyline({
              paths : feature.paths,
              spatialReference : spatialRef
            });*/
            
            //console.log("b 1 ", feature);
            var polyline = _this.geometryEngine.union(feature.gset);

            var graph = new _this.Graphic({
              geometry : polyline,
              attributes : {
                "OBJECTID" : feature.id,
                "STREET_NAME" : sname,
                "TYPE" : feature.type,
                "GENERIQUE" : feature.generique,
                "LIAISON" : feature.liaison,
                "SPECIFIQUE" : feature.specifique,
                "DIRECTION" : feature.direction,
                "NOM_TOPOGRAPHIE" : feature.nom_topographique,
                "COTE" : feature.cote,
                "ORIENTATION" : feature.orientation
              }
            });
            return graph;
          });
          _this.searchLayer = new _this.FeatureLayer({
            //source : source,
            source : [],
            displayField : "STREET_NAME",
            fields  : [
              new _this.Field({name : "OBJECTID", alias : "OBJECTID", type  : oid }),
              new _this.Field({name : "STREET_NAME", alias : "STREET_NAME", type  : "string"}),
              new _this.Field({name : "TYPE", alias : "TYPE", type  : "string"}),
              new _this.Field({name : "GENERIQUE", alias : "GENERIQUE", type  : "string"}),
              new _this.Field({name : "LIAISON", alias : "LIAISON", type  : "string"}),
              new _this.Field({name : "SPECIFIQUE", alias : "SPECIFIQUE", type  : "string"}),
              new _this.Field({name : "DIRECTION", alias : "DIRECTION", type  : "string"}),
              new _this.Field({name : "NOM_TOPOGRAPHIE", alias : "NOM_TOPOGRAPHIE", type  : "string"}),
              new _this.Field({name : "COTE", alias : "COTE", type  : "string"}),
              new _this.Field({name : "ORIENTATION", alias : "ORIENTATION", type  : "string"})
            ],
            objectIdField : "OBJECTID",
            popupEnabled :  false,
            outFields    : ["*"],
            visible : false
            /*opacity : 0.3,
            renderer : {
              type : "simple",
              symbol : {
                type : "simle-line",
                width : "3px",
                color : "#ffffff"
              }
            }*/
          });
        _this.map.add(_this.searchLayer);
        callback();
        }
      };
      streetsQuery.num   = size;
      streetsQuery.from  = from;
      _this.layerStreets.queryFeatures(streetsQuery).then(responseFun);
    },
    
    addSearch(Search,Locator) {
      // Search widget
      //var addSearchFun = function(){let search = new Search({
      let search = new Search({
          view : this.view,
          autoSelect : true,
          label : "Some Label",
          locationEnabled : false,
          minSuggestCharacters : 3,
          popupEnabled : false,
          searchAllEnabled : true,
          declaredClass: 'hello',
          includeDefaultSources : (!!this.features.searchSource && this.features.searchSource.isDefaultESRI) || false,
          resultGraphicEnabled : true,
          sources : [],
          resultSymbol :{
                type: "picture-marker",
                url: window.location.protocol + '//'+ window.location.host +  "/static/img/icons/pin.png",
                size: 24,
                width: 24,
                height: 24,
                xoffset: 0,
                yoffset: 0
              }
      });
      if(!!this.features.searchSource && this.features.searchSource.isQuebec){
        search.sources.push(
          {
              locator : new Locator({
                url: "https://servicescarto.mern.gouv.qc.ca/pes/rest/services/Territoire/AdressesQuebec_Geocodage/GeocodeServer"
              }),
              singleLineFieldName: "SingleLine",
              name: "Adresses Quebec Geocodage",
              placeholder: this.$i18n.t('mapPage.serchePlaceHolder'),
              maxResults: 50,
              maxSuggestions: 6,
              outFields: ["*"],
              resultGraphicEnabled: true,
              resultSymbol :{
                type: "picture-marker",
                url: window.location.protocol + '//'+ window.location.host +  "/static/img/icons/pin.png",
                size: 24,
                width: 24,
                height: 24,
                xoffset: 0,
                yoffset: 0
              } 
            }
        )
      }

      if(!!this.features.searchSource && this.features.searchSource.isDefaultCenterline){
        search.sources.push(
          {
            layer : this.layerStreets,
            searchFields : (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].searchFields:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].searchFields:RoadNetwork[this.instance.city].searchFields),
            displayField : (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].displayField:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].displayField:RoadNetwork[this.instance.city].displayField),
            exactMatch   : false,
            prefix: "",
            suffix: "",
            maxResults: 50,
            maxSuggestions: 6,
            name         : "Rue",
            outFields    : ["*"],
            placeholder  : this.$i18n.t('mapPage.serchePlaceHolder'),
            resultGraphicEnabled : false
          }
        )
      }
      let _this = this;
        /*search.on("search-complete", function(event){
          _this.cleanSearchGraph();
          let paths = [], dists = [];
          
          paths = event.results[0].results.map(function(result){
            dists.push(5);
            return result.feature.geometry;
          });
          if (paths.length > 0){
            _this.searchBuffer = _this.geometryEngine.geodesicBuffer(paths, dists, _this.conflictsUnits, true)[0];
            var g = new _this.Graphic({
              geometry : _this.searchBuffer,
              visible : true,
              symbol  : {
                type: "simple-fill",
                color: _this.drawColors.search,
                style: "backward-diagonal",
                outline: {  // autocasts as new SimpleLineSymbol()
                  color: _this.drawColors.search,
                  width: 3
                }
              }
            });
            _this.drawLayer.add(g);
            _this.searchGraph = g;
          }
          
        });*/
        this.view.ui.add(search, "top-right");
      //};
    //this.fillSearchLayer(addSearchFun);
    //addSearchFun();
    },
    
    cleanSearchGraph(){
      let _this = this;
      if(_this.searchGraph) {
        _this.drawLayer.remove(_this.searchGraph);
        _this.searchGraph=null;
      }
      if(_this.searchBuffer){
        delete _this.searchBuffer;
        _this.searchBuffer=null;
      }
    },
    
    computeAngle(pointA, pointB){
      let _this = this;
      //var dLon = (pointB.x - pointA.x) * Math.PI / 180;
      //var lat1 = pointA.y * Math.PI / 180;
      //var lat2 = pointB.y * Math.PI / 180;
      //var y = Math.sin(dLon) * Math.cos(lat2);
      //var x = Math.cos(lat1)*Math.sin(lat2) - Math.sin(lat1)*Math.cos(lat2)*Math.cos(dLon);
      //var bearing = Math.atan2(y, x)  * 180 / Math.PI;
      //return ((bearing + 360) % 360).toFixed(1);

      var A = _this.webMercatorUtils.webMercatorToGeographic(pointA);
      var B = _this.webMercatorUtils.webMercatorToGeographic(pointB);

      let geoRes = _this.geodesicUtils.geodesicDistance(A,B);
      return geoRes.azimuth;
    },
  
    drawPointMarker(point, options){
      let _this = this;
      var graph;

      graph =  new _this.Graphic({
        visibility : true,
        geometry   : point,
        symbol     : {
          type  : options.type?options.type:"simple-marker",
          color : options.color?options.color:_this.drawColors.drawing,
          angle : options.angle,
          style : options.style,
          size  : options.size,
          outline : {
            color : options.outlineColor,
            width : options.outlineWidth
          }
        }
      });
      //_this.drawLayer.remove(_this.selectionStartMark);
      //_this.drawLayer.remove(_this.selectionEndMark);
      //_this.selectionStartMark = sgraph;
      _this.drawLayer.add(graph);
      //_this.drawLayer.add(sgraph);
    return graph;
    },
    
    getLineEgdes(line, sens){
      var x;
      if((line.type = "polyline")){
        let s = sens?sens:"straight";
        if (s == "straight"){
          let length = line.paths.length;
          let last   = line.paths[length - 1];
          x = [line.getPoint(length - 1, last.length - 2), line.getPoint(length - 1, last.length - 1)];
          return x;
        }else{
          x = [line.getPoint(0, 1), line.getPoint(0, 0)];
          return x;
        }
      }else{throw("getLineEgdes: bad argument ", line);}
    },
    
    viewArmPMEvent() {
      this.viewArmPMEventHdlr = this.view.on("pointer-move", this.viewArmPMEventFun);
    },
    viewArmClkEvent() {
      this.view.on("click", this.viewArmClkFun);
    },
    viewArmDblClkEvent() {
      this.view.on("double-click", this.viewArmDblClkFun);
    },
    
    viewUnarmPMEvent () {
      if (this.viewArmPMEventHdlr){
        this.viewArmPMEventHdlr.remove();
        this.viewArmPMEventHdlr = null;
      }
    },
    
    viewArmKeyDwnEvent(){
      //this.viewArmKeyDwnEventHdlr = 
      this.view.on("key-down", this.viewArmKeyDwnEventFun);
    },
    
    viewArmKeyUpEvent(){
      //this.viewArmKeyUpEventHdlr =
      this.view.on("key-up", this.viewArmKeyUpEventFun);
    },
    
    savePointGeometry(object, type) {
      object.suggest = false;
      this.entrave.selected.path.push(object);
      this.entrave.type = type || 'ponctuel';
      this.entrave.label = this.streetName(object);
      this.entrave.value = this.streetName(object);
      this.entrave.name = this.streetName(object);
      this.updateConflictsBuffer();
    },
    drawSelectedPoint() {
      let _this = this;
      
      this.selectMode = "point";
      _this.entrave = {
        label: _this.selectedEntrave.label,
        value: _this.selectedEntrave.value,
        selected : {
          path : _this.selectedEntrave.selected.path,
          startPoint : _this.selectedEntrave.selected.startPoint,
          endPoint   : _this.selectedEntrave.selected.endPoint,
          streetsTrack : _this.selectedEntrave.selected.streetsTrack
        },
        type: _this.selectedEntrave.type
      };
      console.log(" copied ", _this.entrave);
      /*if (_this.mapMode === "create"){
        _this.entrave = _this.selectedEntrave;
      }else{
        //let p = _this.selectedEntrave.selected.path[0];
        _this.entrave.label = _this.selectedEntrave.label;
        _this.entrave.value = _this.selectedEntrave.value;
        _this.entrave.type  = _this.selectedEntrave.type;
        _this.entrave.selected = _this.selectedEntrave.selected;
        _this.entrave.selected.path       = [{
          geometry : new _this.Point({
            latitude  : p.geometry.latitude,
            longitude : p.geometry.longitude,
            x : p.geometry.x,
            y : p.geometry.y,
            spatialReference : p.geometry.spatialReference,
            hasM : p.geometry.hasM,
            hasZ : p.geometry.hasZ
          }),
          attributes : p.attributes,
          segment : {
            geometry : new _this.Polyline({
              paths : p.segment.geometry.paths,
              spatialReference : p.segment.geometry.spatialReference,
              hasM : false,
              hasZ : false
            }),
            attributes : p.segment.attributes
          }
        }];
        //_this.entrave.selected.streetsTrack = [];
      }*/
      let point = _this.entrave.selected.path[0];
      let segment = _this.entrave.selected.path[0].segment;
      let graph = new _this.Graphic({
        geometry   : point.geometry,
        attributes : point.attributes,
        visible    : true,
        symbol: {
          type: "simple-marker",
          color: _this.drawColors.drawing,
          outline: {
            color: [ 255, 255, 0 ],
            width: 1
          }
        }
      });
      _this.drawLayer.add(graph);
      graph = new _this.Graphic({
        geometry   : segment.geometry,
        attributes : segment.attributes,
        visible    : true,
        symbol : {
          cap   : "round",
          join  : "round",
          type  : "simple-line",
          color : _this.drawColors.drawing,
          width : "1px",
          style : "short-dash"
        }
      });
      _this.drawLayer.add(graph);
      _this.updateConflictsBuffer();
      _this.view.goTo(_this.drawLayer.graphics.toArray());
      _this.viewUnarmPMEvent();
    },
    drawSelectedEntrave() {
      let _this=this;
      var sens, start, end;
      _this.mode = 1;
      _this.hovered.forEach(function(g){_this.drawLayer.remove(g);});
      _this.viewUnarmPMEvent();
      
      let suggest = _this.selectedEntrave.selected.path[0].suggest;

      if (_this.mapMode === "edit"){
        _this.entrave = JSON.parse(JSON.stringify(_this.selectedEntrave));
        _this.entrave.label = '';
        _this.entrave.value = '';
        //_this.entrave.type  = _this.selectedEntrave.type;
        _this.entrave.selected.path       = [];
        _this.entrave.selected.streetsTrack = [];
      }else{
        _this.entrave = _this.selectedEntrave;
      }
      _this.selectedEntrave.selected.path.forEach(function(object) {
        var geometry = object.geometry;
        /*if(_this.mapMode === "create"){
          geometry = object.geometry;
        }else {
          geometry = {
              type : "polyline",
              paths : object.geometry.paths,
              spatialReference : object.geometry.spatialReference,
              hasM : false,
              hasZ : false
            };
        }*/
        var graph = new _this.Graphic({
            geometry   : geometry,
            attributes : object.attributes,
            visible    : true,
            symbol : {
              cap   : "round",
              join  : "round",
              type  : "simple-line",
              color : _this.drawColors.drawing, 
              width : "3px",
              style : "solid"
            }
        });
        if (_this.mapMode === "edit"){
          _this.pushSelectedSegment(graph);
        }
        _this.drawLayer.add(graph);
        //_this.entrave.selected = _this.selectedEntrave.selected;
        if(suggest !== false){
          _this.getIntersections(_this.layerView, geometry);
        }else{_this.selectMode = "point";}
        //[start, end] = _this.getLineEgdes(directionArrwLine, sens);
        //let ang = _this.computeAngle(start, end);
        //_this.drawArrowMarker(ang, end);
      });
      //drawing new arraow
      var object       = _this.entrave.selected.path[_this.entrave.selected.path.length - 1];
      var lastPathIdx  = object.geometry.paths.length - 1;
      var lastPointIdx = object.geometry.paths[lastPathIdx].length - 1;
      
      if (_this.geometryEngine.equals(_this.entrave.selected.endPoint, object.geometry.getPoint(lastPathIdx, lastPointIdx))) {
        sens = "straight";
      }else{
        sens = "reversed";
      }

      // Updating markers and buffers
      [start, end] = _this.getLineEgdes(object.geometry, sens);
      let ang = _this.computeAngle(start, end);
      _this.drawArrowMarker(ang, end);
      //_this.entrave.selected.streetsTrack.reverse();
      _this.updateStreetsLabel();
      _this.updateConflictsBuffer();
      _this.view.goTo(_this.drawLayer.graphics.toArray());
    },
    gotoSelection(){
      let _this = this;
      if (_this.entrave.type === "linear"){
        _this.view.goTo(_this.entrave.selected.path);
      }else{
        let p = new _this.Point({
          longitude : _this.entrave.selected.path[0].geometry.longitude,
          latitude  : _this.entrave.selected.path[0].geometry.latitude
        });
        _this.view.goTo(p);
      }
    },
    deleteFirst(){
      let _this = this;
      if ((_this.entrave.type === "linear") && (_this.entrave.selected.path.length> 0)){
        if(_this.entrave.selected.path.length === 1){
          _this.cleanMap(false);
        }else{
          var object = _this.entrave.selected.path.shift();
          // remove segment from graph
          _this.drawLayer.remove(object);
          
          // remove possible segments conected to deleted segment
          var possibleToDel = [];
          _this.possibleSegments.forEach(function(possible){
            if (_this.geometryEngine.distance(possible.object.geometry, object.geometry, "meters") <= 10){
              possibleToDel.push(possible);
            }
          });
          possibleToDel.forEach(function(toDel){_this.deletePossibleSegments(toDel.object);});
          // Set new Start Point
          
          //search for connection Point
          var lastPathIdx  = object.geometry.paths.length - 1;
          var lastPointIdx = object.geometry.paths[lastPathIdx].length - 1;
          
          var sp = _this.entrave.selected.startPoint;
          var objStartPnt = object.geometry.getPoint(0, 0);
          var objEndPnt   = object.geometry.getPoint(lastPathIdx, lastPointIdx);
          
          var connectPoint;
          if (_this.geometryEngine.equals(sp, objStartPnt)){
            connectPoint = objEndPnt;
          }else{
            connectPoint = objStartPnt;
          }
          
          // fins new start point
          var newFirst = _this.entrave.selected.path[0];
          lastPathIdx  = newFirst.geometry.paths.length - 1;
          lastPointIdx = newFirst.geometry.paths[lastPathIdx].length - 1;
          
          var newFirstStart = newFirst.geometry.getPoint(0, 0);
          var newFirstEnd   = newFirst.geometry.getPoint(lastPathIdx, lastPointIdx);
          var newStart;
          if (_this.geometryEngine.distance(connectPoint, newFirstStart) <= 5){
            newStart = newFirstStart;
          }else{
            newStart = newFirstEnd;
          }
          //save new Start and draw new possible segments
          _this.entrave.selected.startPoint = newStart;
          _this.getIntersections(_this.layerView, newStart);
          
          _this.drawLayer.remove(_this.selectionStartMark);
          _this.selectionStartMark = _this.drawPointMarker(_this.entrave.selected.startPoint, {
            type  : "simple-marker",
            color : _this.drawColors.drawing,
            angle : 0,
            style : "circle",
            size  : "20px",
            outlineColor : _this.drawColors.drawing,
            outlineWidth : "0px"
          });

          _this.removeStreetsTrackSegment(object, "start");
          _this.updateStreetsLabel();
          //update conflicts buffer
          _this.updateConflictsBuffer();
          
        }
      }else if(_this.entrave.type === "ponctuel"){
       _this.cleanMap(false); 
      }
      
    },
    deleteLast(){
      let _this = this;
      if ((_this.entrave.type === "linear") && (_this.entrave.selected.path.length> 0)){
        if(_this.entrave.selected.path.length === 1){
          _this.cleanMap(false);
        }else{
          var object = _this.entrave.selected.path.pop();
          // remove segment from graph
          _this.drawLayer.remove(object);
          
          // remove possible segments conected to deleted segment
          var possibleToDel = [];
          _this.possibleSegments.forEach(function(possible){
            if (_this.geometryEngine.distance(possible.object.geometry, object.geometry, "meters") <= 10){
              possibleToDel.push(possible);
            }
          });
          possibleToDel.forEach(function(toDel){_this.deletePossibleSegments(toDel.object);});
          // Set new End Point
          
          //search for connection Point
          var lastPathIdx  = object.geometry.paths.length - 1;
          var lastPointIdx = object.geometry.paths[lastPathIdx].length - 1;
          
          var ep = _this.entrave.selected.endPoint;
          var objStartPnt = object.geometry.getPoint(0, 0);
          var objEndPnt   = object.geometry.getPoint(lastPathIdx, lastPointIdx);
          
          var connectPoint;
          if (_this.geometryEngine.equals(ep, objStartPnt)){
            connectPoint = objEndPnt;
          }else{
            connectPoint = objStartPnt;
          }
          
          // fins new start point
          var newLast = _this.entrave.selected.path[_this.entrave.selected.path.length-1];
          lastPathIdx  = newLast.geometry.paths.length - 1;
          lastPointIdx = newLast.geometry.paths[lastPathIdx].length - 1;
          
          var newLastStart = newLast.geometry.getPoint(0, 0);
          var newLastEnd   = newLast.geometry.getPoint(lastPathIdx, lastPointIdx);
          var newEnd;
          let sens;
          
          if (_this.geometryEngine.distance(connectPoint, newLastStart) <= 5){
            sens = "reversed";
            newEnd = newLastStart;
          }else{
            sens = "straight";
            newEnd = newLastEnd;
          }
          
          //save new Start and draw new possible segments
          _this.entrave.selected.endPoint = newEnd;
          _this.getIntersections(_this.layerView, newEnd);
          
          let start, end;
          [start, end] = _this.getLineEgdes(newLast.geometry, sens);
          let ang = _this.computeAngle(start, end);
          _this.drawLayer.remove(_this.selectionDirectionArrowMarker);
          _this.selectionDirectionArrowMarker = _this.drawPointMarker(newEnd,
          {
            type  : "simple-marker",
            color : _this.drawColors.drawing,
            angle : ang,
            style : "triangle",
            size  : "15px",
            outlineColor : _this.drawColors.drawing,
            outlineWidth : "0px"
          });
          
          _this.removeStreetsTrackSegment(object, "end");
          _this.updateStreetsLabel();
          //update conflicts buffer
          _this.updateConflictsBuffer();
          
        }
      }else if(_this.entrave.type === "ponctuel"){
       _this.cleanMap(false); 
      }
    },
    esriToTurf(geometry){
      let _this = this;
      let geographic;
      if (geometry.spatialReference.isWebMercator){
        geographic = _this.webMercatorUtils.webMercatorToGeographic(geometry);
      }else{geographic = geometry;}
      var converted;
      switch (geometry.type){
        case "point" :
          converted = turf.point([geographic.longitude, geographic.latitude]);
          break;
        case "polyline" :
          converted = turf.lineString(geographic.paths.flat());
          break;
        default:
          converted = null;
          break;
      }
      return converted;
    },
    trufToEsri(geometry){
      console.log("Geom ", geometry);
    },
    splitLine(polyline, point){
      let _this = this;
        let tPoint    = _this.esriToTurf(point);
        let tPolyline = _this.esriToTurf(polyline);
        var split = turf.lineSplit(tPolyline, tPoint);
        let tSegment1 = split.features[0];
        let tSegment2 = split.features[1];
        
        let segment1 = _this.webMercatorUtils.geographicToWebMercator(new _this.Polyline({
          paths : [tSegment1.geometry.coordinates],
          //spatialReference : polyline.spatialReference
          spatialReference : _this.spatialReference.WGS84
        }));
        let segment2 = _this.webMercatorUtils.geographicToWebMercator(new _this.Polyline({
          paths : [tSegment2.geometry.coordinates],
          //spatialReference : polyline.spatialReference
          spatialReference : _this.spatialReference.WGS84
        }));
        return [segment1, segment2];
    },
    subPolyline(polyline, startP, endP){
      //console.log("S Point\nx: ", startP.x, "\ny: ", startP.y);
      let _this = this;
      var subSeg;
      let firstSliceL, firstSliceR, secondSliceL, secondSliceR;
      [firstSliceL, firstSliceR]   = _this.splitLine(polyline, startP);
      [secondSliceL, secondSliceR] = _this.splitLine(polyline, endP);
      /*console.log("FL ", firstSliceL.paths[0].toString());
      console.log("FR ", firstSliceR.paths[0].toString());
      console.log("SL ", secondSliceL.paths[0].toString());
      console.log("SR ", secondSliceR.paths[0].toString());*/
      
      /*var g = new _this.Graphic({
        geometry : secondSliceR,
        symbol : {
          type  : "simple-line",
          color : "red",
          width : "3px",
          style : "solid"
        }
      });
      _this.drawLayer.add(g);*/
      
      if (_this.geometryEngine.intersects(secondSliceR, startP)){
        console.log("XX");
        //subSeg = _this.completeBorders(_this.geometryEngine.intersect(secondSliceR, firstSliceL), endP, startP);
        subSeg = _this.intersect(secondSliceR, startP);
      }else{
        console.log("XY");
        //subSeg = _this.completeBorders(_this.geometryEngine.intersect(firstSliceR, secondSliceL), startP, endP);
        subSeg = _this.intersect(firstSliceR, endP);
      }
      return subSeg;
    },
    completeBorders(polyline, startP, endP){
      let _this = this;
      let path = polyline.paths[0];
      let length = path.length;
      let fp = polyline.getPoint(0,0);
      let ep = polyline.getPoint(polyline.paths.length - 1, length-1);
      var newPath = polyline.paths[0];
      
      if (!_this.geometryEngine.equals(startP, fp)){
        newPath.unshift([startP.x, startP.y]);
      }
      if (!_this.geometryEngine.equals(endP, ep)){
        newPath.push([endP.x, endP.y]);
      }
      polyline.paths = [newPath];
      return polyline;
    },
    intersect(polyline, point){
      let _this = this;
      let paths = polyline.paths[0];
      var subPath = [], found = false, simpleLine, simplePath;
      let i = 0, segIdx;
      let d, minD = -1;
      
      while(!found && i < paths.length - 1){
        simplePath = [paths[i], paths[i + 1]];
        simpleLine = new _this.Polyline({
          paths : [simplePath],
          spatialReference : polyline.spatialReference
        });
        if (_this.geometryEngine.intersects(simpleLine, point)){
          found  = true;
          segIdx = i;
        }else{
          d = _this.geometryEngine.distance(simpleLine, point);
          if(minD === -1){
            minD   = d;
            segIdx = i;
          }else{
            if (d < minD){
              minD   = d;
              segIdx = i;
            }
          i++;
          }
        }
      }
      for (i=0; i<= segIdx;i++){
        subPath.push(paths[i]);
      }
      subPath.push([point.x, point.y]);
      var r = new _this.Polyline({
        paths : [subPath],
        spatialReference : polyline.spatialReference
      });
      return r;
    },
    test(){
      let _this = this;
      var g;
      console.log("test");
      var sp = new _this.Point({
        x:  -7937854.243445261,
        y:  5924005.3227097625,
        spatialReference : _this.spatialReference.WebMercator
      });
      console.log("a");
      g = new _this.Graphic({
        geometry : sp,
        symbol : {
          type  : "simple-marker",
          color : "blue",
          size  : "3px"
        }
      });
      console.log("b");
      _this.drawLayer.add(g);
      console.log("c");
      var fl = new _this.Polyline({
        paths : [[ [-7937101.407539546,5924581.0938639],[-7937122.124517056,5924580.821840039],[-7937207.717141997,5924541.28045679],[-7937492.9543009745,5924373.05626994],
                  [-7937511.591698965,5924362.169932609],[-7937556.661696687,5924334.275016721],[-7937659.234556825,5924271.541060811],[-7937704.466394546,5924236.42677141],
                  [-7937737.641686517,5924203.4515513405],[-7937770.347462646,5924162.59723457],[-7937798.076790146,5924120.68350651],[-7937843.970024027,5924025.803406562],
                  [-7937854.243445261,5924005.3227097625] ]],
        spatialReference : _this.spatialReference.WebMercator
      });
      console.log("d");
      g = new _this.Graphic({
        geometry : fl,
        symbol : {
          type  : "simple-line",
          color : "green",
          width : "3px",
          style : "solid"
        }
      });
      console.log("e");
      _this.drawLayer.add(g);
      _this.view.goTo(g);
      console.log("f");
    },
    loadMap() {
      loadModules([
      "esri/Map",
      "esri/Basemap",
      "esri/views/MapView",
      "esri/layers/FeatureLayer",
      "esri/symbols/SimpleLineSymbol",
      "esri/layers/GraphicsLayer",
      "esri/geometry/Extent",
      "esri/layers/support/Field",
      "esri/geometry/Polyline",
      "esri/geometry/Point",
      "esri/tasks/support/Query",
      "esri/geometry/geometryEngine",
      "esri/geometry/support/geodesicUtils",
      "esri/Graphic",
      "esri/geometry/support/webMercatorUtils",
      "esri/geometry/SpatialReference",
      "esri/widgets/Search",
      "esri/layers/VectorTileLayer",
      "esri/tasks/Locator",
      "esri/widgets/Legend",
      "esri/config",
    ])
      .then(([Map, BaseMap, MapView, FeatureLayer, SimpleLineSymbol, GraphicsLayer, Extent, Field, Polyline, Point, Query, geometryEngine, geodesicUtils, Graphic, webMercatorUtils, spatialReference, Search,VectorTileLayer,Locator,Legend,EsriConfig]) => {
        let _this=this;
        EsriConfig.apiKey = process.env.VUE_APP_ARCGIS_API_KEY; 
        this.initLoad(Map, BaseMap, MapView, FeatureLayer, SimpleLineSymbol, GraphicsLayer, geodesicUtils, Field, Polyline, Point, Graphic, geometryEngine, webMercatorUtils, spatialReference, Extent,VectorTileLayer,Legend);
        
        this.view.whenLayerView(_this.layerStreets).then(function(layerView){
          _this.mapLoad=true;
          _this.layerView=layerView;
          _this.view.when(()=>{
            _this.addSearch(Search,Locator);
          })
          
          _this.conflictsRadius = _this.perimeter?_this.perimeter:1;
          
          _this.viewArmPMEvent();
          _this.viewArmClkEvent();
          //_this.viewArmDblClkEvent();
          _this.viewArmKeyDwnEvent();
          _this.viewArmKeyUpEvent();
          _this.view.focus();
          _this.possibleSegments= [];
          if (_this.selectedEntrave && _this.selectedEntrave.selected.path.length > 0 ) {
            if(_this.selectedEntrave.type=='ponctuel') {
              _this.drawSelectedPoint();
            } else {
              _this.drawSelectedEntrave(); 
            }
          }
        });
      });   
    },
    viewArmPMEventFun (event) {
      // Pointer Move function
      // cleaning hivered streets
      let _this=this;
      if (_this.hovered) {_this.hovered.forEach(function(g){_this.drawLayer.remove(g);});}
      this.hovered = [];
      event.stopPropagation();
      //viewUnarmPMEvent();
      //latestPoint = view.toMap({x: event.x, y: event.y});
      var query = this.layerStreets.createQuery();
      query.geometry = this.createPointSearchExtent(event, 5);
      query.spatialRelationship = "intersects";
      query.returnGeometry = true;
      query.outFields = (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].queryOutFields:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].queryOutFields:RoadNetwork[this.instance.city].queryOutFields);
      this.layerStreets.queryFeatures(query).then(function(result){
        result.features.forEach(function(object){
          var graph = new _this.Graphic({
              geometry   : object.geometry,
              attributes : object.attributes,
              visible    : true,
              symbol : {
                cap   : "round",
                join  : "round",
                type  : "simple-line",
                color : _this.drawColors.drawing,
                width : "2px",
                style : "short-dash"
              }
          });
          _this.hovered.push(graph);
          _this.drawLayer.add(graph);
        });
      });
    },
    viewArmDblClkFun(event) {
      let _this = this;
      event.stopPropagation();
      _this.cleanSearchGraph();
      let query = this.layerStreets.createQuery();
      query.geometry = _this.createPointSearchExtent(event, 5);
      query.spatialRelationship = "intersects";
      query.returnGeometry = true;
      query.outFields = (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].queryOutFields:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].queryOutFields:RoadNetwork[this.instance.city].queryOutFields);

      
      this.layerStreets.queryFeatures(query).then(function(result){
        if (result.features.length){_this.viewUnarmPMEvent();}
        /*let point = {
          type: "point", // autocasts as /Point
          x: event.mapPoint.x,
          y: event.mapPoint.y,
          spatialReference: 102100
        };*/
        var graph, object; 
        if (_this.entrave.selected.path.length === 0) {
          object = {
            /*geometry: {
              type: "point",
              longitude: event.mapPoint.longitude,
              latitude: event.mapPoint.latitude
            },*/
            geometry : event.mapPoint,
            attributes: result.features[0].attributes,
            segment: result.features[0]
          };
          _this.selectMode = "point";
          let nearset = _this.geometryEngine.nearestCoordinate(result.features[0].geometry, event.mapPoint);
          if (nearset && nearset.coordinate && nearset.coordinate.longitude && nearset.coordinate.latitude) {
            object.geometry.longitude = nearset.coordinate.longitude;
            object.geometry.latitude = nearset.coordinate.latitude;
          }
          graph = new _this.Graphic({
            geometry   : object.geometry,
            attributes : object.attributes,
            visible    : true,
            symbol: {
              type: "simple-marker",
              color: _this.drawColors.drawing,
              outline: {
                color: _this.drawColors.drawing,
                width: 5
              }
            }
          });
          _this.pointSelectMark = graph;
          _this.drawLayer.add(graph);
          graph = new _this.Graphic({
            geometry   : result.features[0].geometry,
            attributes : result.features[0].attributes,
            visible    : true,
            symbol : {
              cap   : "round",
              join  : "round",
              type  : "simple-line",
              color : _this.drawColors.drawing,
              width : "1px",
              style : "short-dash"
            }
          });
          _this.drawLayer.add(graph);
          _this.savePointGeometry(object);
        } else if(_this.entrave.selected.path.length === 1 && _this.entrave.type === "ponctuel"){
          object = _this.entrave.selected.path[0];
          let firstPoint = _this.entrave.selected.path[0].geometry;
          console.log("Sub Segment ", firstPoint.x, " : ", firstPoint.y);
          _this.selectSubSegment(event, object, result, firstPoint);
        } else if(_this.entrave.selected.path.length === 1 && _this.entrave.selected.path[0].suggest === false){ //already a subsegment has been selected, edition mode
          object = _this.entrave.selected.path[0];
          console.log("Segment Edit");
          let firstPoint = _this.entrave.selected.path[0].geometry.getPoint(0,0);
          console.log("Segment Edit ", firstPoint.x , " : ", firstPoint.y);
          _this.selectSubSegment(event, object, result, firstPoint);
        }
      });
    },
    viewArmKeyDwnEventFun (event){
      let _this = this;
      event.native.preventDefault();
      _this.downKeys.char=event.key;
      if (!_this.downKeys.isDown){
        switch (event.key) {
          case 'Control':
            _this.downKeys.control = true;
            break;
          case 'Shift':
            _this.downKeys.control = true;
            break;
          case 'i':
            //event.native.preventDefault();
            if(!_this.downKeys.isDown && _this.downKeys.control && !event.repeat){
              _this.downKeys.isDown = true;
              _this.reversePath();              
            }
            //else{event.native.preventDefault();}
            break;
          case ' ':
            if(!_this.downKeys.isDown && !_this.downKeys.control && !event.repeat){
              _this.downKeys.isDown = true;
              _this.gotoSelection();
            }
            break;
          case 'Delete':
            if(!_this.downKeys.isDown && !_this.downKeys.control && !event.repeat){
              _this.downKeys.isDown = true;
              _this.deleteFirst();
            }
            break;
          case 'Backspace':
            if(!_this.downKeys.isDown && !_this.downKeys.control && !event.repeat){
              _this.downKeys.isDown = true;
              _this.deleteLast();
            }
            break;
          default:
            break;
        }
      }
    },
    
    viewArmKeyUpEventFun(){
    let _this = this;
    if((event.key === 'Control') || (event.key === 'Shift')){_this.downKeys.control = false;}
    else{
      _this.downKeys.isDown  = false;
      _this.downKeys.char='';
      }
    },
    
    selectSubSegment(event, object, result, firstPoint){
      let _this = this;
      let paths = _this.entrave.selected.path;
      //if(result.features[0].attributes.ID==paths[0].attributes.ID) {
      if(result.features[0].attributes[RoadNetwork[_this.instance.city].fieldId]==paths[0].attributes[RoadNetwork[_this.instance.city].fieldId]) {
        _this.drawLayer.removeAll();
        //let nearset = _this.geometryEngine.nearestCoordinate(result.features[0].geometry, event.mapPoint);
        let nearset = _this.geometryEngine.nearestCoordinate(paths[0].segment.geometry, event.mapPoint);
        let clkPoint = event.mapPoint.clone();

        if (nearset && nearset.coordinate && nearset.coordinate.longitude && nearset.coordinate.latitude) {
          clkPoint.longitude = nearset.coordinate.longitude;
          clkPoint.latitude = nearset.coordinate.latitude;
        }
        
        //let subSegment = _this.subPolyline(result.features[0].geometry, firstPoint, clkPoint);
        let subSegment = _this.subPolyline(paths[0].segment.geometry, firstPoint, clkPoint);
        
        object.geometry = subSegment;
        var graph = new _this.Graphic({
          geometry   : object.geometry,
          attributes : object.attributes,
          visible    : true,
          symbol : {
            cap   : "round",
            join  : "round",
            type  : "simple-line",
            color : _this.drawColors.drawing, 
            width : "3px",
            style : "solid"
          }
        });
        _this.drawLayer.add(graph);
        if(_this.pointSelectMark){
          _this.drawLayer.remove(_this.pointSelectMark);
          _this.pointSelectMark = null;
        }
        graph = new _this.Graphic({
          geometry   : paths[0].segment.geometry,
          attributes : paths[0].segment.attributes,
          visible    : true,
          symbol : {
            cap   : "round",
            join  : "round",
            type  : "simple-line",
            color : _this.drawColors.drawing,
            width : "1px",
            style : "short-dash"
          }
        });
        _this.drawLayer.add(graph);
        /////////
        /*if (object.sg){_this.drawLayer.remove(object.sg);}
        let p = paths[0].segment.geometry.getPoint(0, 0);
        graph = new _this.Graphic({
          geometry : p,
          visible    : true,
          symbol: {
            type: "simple-marker",
            color: "red",
            outline: {
              color: _this.drawColors.drawing,
              width: 2
            }
          }
        });
        _this.drawLayer.add(graph);
        object.sg = graph;
        //*/
        _this.addSubSegment(object);
      } else {
        console.log("différent point...");
      }
    },
    
    drawPolyline(result) {
      this.drawLayer.removeAll();
      let graph = new this.Graphic({
        geometry   : result.features[0].geometry,
        attributes : result.features[0].attributes,
        visible    : true,
        symbol : {
          cap   : "round",
          join  : "round",
          type  : "simple-line",
          color : this.drawColors.drawing,
          width : "1px",
          style : "short-dash"
        }
      });
      this.drawLayer.add(graph);
      let paths = this.entrave.selected.path.map(function(e) {
        return [e.geometry.longitude, e.geometry.latitude]; 
      });
      this.entrave.selected.startPoint = paths[0];
      this.entrave.selected.endPoint = paths[paths.length-1];
      
      let simpleLineSymbol = {
        type: "simple-line",
        color: this.drawColors.drawing,
        width : "3px"
      };

      let polyline = new this.Polyline({
        paths: paths
      });

      let polylineGraphic = new this.Graphic({
        geometry: polyline,
        symbol: simpleLineSymbol
      });

      this.drawLayer.add(polylineGraphic);
      var object       = this.entrave.selected.path[this.entrave.selected.path.length - 1];
      
      
      if (this.geometryEngine.equals(paths[paths.length-1], paths[0])) {
        sens = "straight";
      }else{
        sens = "reversed";
      }

      // Updating markers and buffers
      [start, end] = this.getLineEgdes(object.geometry, sens);
      let ang = this.computeAngle(start, end);
      this.drawArrowMarker(ang, end);
      
    },
    viewArmClkFun(event) {
      let _this=this;
      
      _this.cleanSearchGraph();
      if (event.button === 0){
        //var query = new Query();
        _this.viewUnarmPMEvent();
        if(_this.downKeys.control && (_this.selectMode === "point" || _this.entrave.selected.path.length === 0)){
          _this.viewArmDblClkFun(event);
        }else{
          var query = this.layerStreets.createQuery();
          query.geometry = this.createPointSearchExtent(event, 5);
          query.spatialRelationship = "intersects";
          query.returnGeometry = true;
          query.outFields = ["*"];
          
          //layerView.queryFeatures(query).then(function(result){
          var closest = null, refD = -1, d;
          this.layerStreets.queryFeatures(query).then(function(result){
            var fromHover = false;
            if (result.features.length && _this.mode === 0){
              fromHover = true;
              _this.mode = 1; // change to buildingt mode
              _this.hovered.forEach(function(g){_this.drawLayer.remove(g);});
              _this.viewUnarmPMEvent();
            }
            
            result.features.forEach(function(object){
              var p = _this.inPossibleSegment(object);
              if (!_this.inSelectedSegment(object) && (fromHover || p) ){
                d = _this.geometryEngine.distance(event.mapPoint, object.geometry);
                if (refD === - 1){
                  refD = d;
                  closest = object;
                }else{
                  if(d <= refD ){
                    refD = d;
                    closest = object;
                  }
                }
              }
            });
              
            if(closest){
              var directionArrwLine, sens, start, end;
              var graph = new _this.Graphic({
                 geometry   : closest.geometry,
                 attributes : closest.attributes,
                 visible    : true,
                 symbol : {
                   cap   : "round",
                   join  : "round",
                   type  : "simple-line",
                   color : _this.drawColors.drawing, 
                   width : "3px",
                   style : "solid"
                 }
             });
             [directionArrwLine, sens] = _this.pushSelectedSegment(graph);
             _this.drawLayer.add(graph);
             //_this.entrave.selected.push({geometry: object.geometry, attributes: object.attributes});
             //getIntersections(layerView, createGeomtSearchExtent(object.geometry));
             _this.getIntersections(_this.layerView, closest.geometry);
             
             [start, end] = _this.getLineEgdes(directionArrwLine, sens);
             let ang = _this.computeAngle(start, end);
             _this.drawArrowMarker(ang, end);
               
             if (!fromHover) {_this.deletePossibleSegments(closest);}
            }
              
            
          });
        }
      } else if (event.button === 2) {
        this.cleanMap();
        //_this.deleteLast();
      }
    },
    reverseSegment(polyline){
      if(polyline.type && polyline.type === "polyline"){
        let _this = this;
        var paths = polyline.paths.reverse().map(function(lineString){
          var reversed = lineString.slice(0, lineString.length);
          reversed.reverse();
          return reversed;
        });
        return (new _this.Polyline({
          paths : paths,
          spatialReference : polyline.spatialReference
        }));
      }
      
    },
    cleanMap(reload){
      let doReload = reload || false;
      console.log("== Cleaing the MAP");
      /*delete this.selectedSegments.startPoint;
      delete this.selectedSegments.endPoint;
      delete this.selectedSegments.path;
      delete this.selectedSegments.streetsTrack;*/
      
      this.selectMode = "segment";
      delete this.entrave.selected.startPoint;
      delete this.entrave.selected.endPoint;
      delete this.entrave.selected.path;
      delete this.entrave.selected.streetsTrack;
      
      this.drawLayer.removeAll();
      
      this.entrave.selected.startPoint=null;
      this.entrave.selected.endPoint = null;
      this.entrave.selected.path = [];
      this.entrave.selected.streetsTrack = [];
      this.possibleSegments = [];
      this.conflictsBuffer = null;
      this.conflictsGraph  = null;
      //this.searchBuffer = null;
      this.searchGraph = null;

      this.mode = 0;
      this.viewArmPMEvent();
      
      this.entrave.label = '';
      this.entrave.value = '';
      
      if (doReload && this.mapMode === "edit"){
        if (this.entrave.type === "linear"){
          this.drawSelectedEntrave();
        }else{
          this.drawSelectedPoint();
        }
      }
    },
    getIntersections(layerView, geometry) {
      let _this=this;
      var query = _this.layerStreets.createQuery();
      query.geometry = geometry;
      query.spatialRelationship = "intersects";
      query.returnGeometry = true;
      query.outFields = ["*"];
      query.distance = 5;
      query.units = "meters";
      
      // query and filter intersecting segments
      _this.layerStreets.queryFeatures(query).then(function(result){
        result.features.forEach(function(object){
          if (!_this.inSelectedSegment(object)){

            var graphic = new _this.Graphic({
              geometry   : object.geometry,
              attributes : object.attributes,
              visible    : true,
              symbol : {
                cap   : "round",
                join  : "round",
                type  : "simple-line",
                color : _this.drawColors.drawing,
                width : "1px",
                style : "short-dash"
              }
            });
            if (_this.pushPossibleSegments(object,graphic)){
              _this.drawLayer.add(graphic);
            }
          }
        });

      });
    },
    
    streetName(object){
      return object.attributes[(RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].displayField:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].displayField:RoadNetwork[this.instance.city].displayField)];
    },

    isPointsClose(point1, point2){
      let _this = this;
      if (_this.geometryEngine.equals(point1, point2)){
        return true;
      }else{
        let d = _this.geometryEngine.distance(point1, point2, "meters");
        return (d <= 5);
      }
    },

    updateStreetsLabel(){
      let _this = this;
      var text = '', textHtml= '', street, streetS, streetE;

      if (_this.entrave.selected.streetsTrack.length === 1){
        street = _this.entrave.selected.streetsTrack[0];
        textHtml = street.name + ' <span class="paths-distance">[' + _this.$i18n.t('projectForm.on') + ' ' + Math.floor(street.distance) + ' m]</span> ';
        text = street.name + ' [' + _this.$i18n.t('projectForm.on') + ' ' + Math.floor(street.distance) + ' m] ';
        _this.entrave.name=street.name;
      }else if(_this.entrave.selected.streetsTrack.length > 1){
        streetS = _this.entrave.selected.streetsTrack[0];
        streetE = street = _this.entrave.selected.streetsTrack[street = _this.entrave.selected.streetsTrack.length - 1];
        textHtml = '<span class="paths-keys">' + _this.$i18n.t('projectForm.from') + ' </span>' + streetS.name + ' <span class="paths-distance">[' + Math.floor(streetS.distance) + 'm]</span> ';
        text = _this.$i18n.t('projectForm.on') + ' ' + streetS.name + ' [' + Math.floor(streetS.distance) + 'm] ';
        for (var i =1; i < _this.entrave.selected.streetsTrack.length - 1; i++){
          street = _this.entrave.selected.streetsTrack[i];
          textHtml += '<span class="paths-keys">  &xrarr; </span>' + street.name + ' <span class="paths-distance">[' + Math.floor(street.distance) + 'm]</span> ';
          text += '  &xrarr; ' + street.name + ' [' + Math.floor(street.distance) + 'm] ';
        }
        textHtml += ' <span class="paths-keys">' +_this.$i18n.t('projectForm.to')+ ' </span>' + streetE.name + ' <span class="paths-distance">[' + Math.floor(streetE.distance) + 'm]</span> ';
        text += ' ' + _this.$i18n.t('projectForm.to') + ' ' + streetE.name + ' [' + Math.floor(streetE.distance) + 'm] ';
        _this.entrave.name=streetS.name;
      }
      _this.entrave.label = textHtml;
      _this.entrave.value = text;
    },
    
    updatedStreetsTrack(street, object, where){
      let _this = this;
      let newSegment, replace, sName;
      var oldPath = _this.entrave.selected.streetsTrack;

      sName = _this.streetName(object);
      if (sName == street.name){
        newSegment = {name : street.name, distance : street.distance + _this.geometryEngine.geodesicLength(object.geometry, "meters")};
        replace = false;
      }else{
        newSegment = {name : _this.streetName(object), distance : _this.geometryEngine.geodesicLength(object.geometry, "meters")};
        replace = true;
      }
      
      // add object in seleted segments in the right order
      if (where == "first"){
        _this.entrave.selected.path.unshift(object);
      }else{
        _this.entrave.selected.path.push(object);
      }
      
      // update street tarck
      if ( (where == "first") && !replace ){
        oldPath[0] = newSegment;
      } else if ( (where == "first") && replace){
        //oldPath.shift();
        oldPath.unshift(newSegment);
      } else if ( (where == "last") && !replace){
        oldPath[oldPath.length - 1] = newSegment;
      } else{
        //oldPath.pop();
        oldPath.push(newSegment);
      }
      _this.updateStreetsLabel();
      return oldPath;
    },
    removeStreetsTrackSegment(object, where){
      let _this = this;
      if (_this.entrave.selected.streetsTrack && _this.entrave.selected.streetsTrack.length){
        var changedSeg;
        if (where === "start"){changedSeg = _this.entrave.selected.streetsTrack[0];}
        else{changedSeg = _this.entrave.selected.streetsTrack[_this.entrave.selected.streetsTrack.length - 1];}
        let sName = _this.streetName(object);
        if(sName === changedSeg.name){
          var dist = Math.floor(changedSeg.distance - _this.geometryEngine.geodesicLength(object.geometry, "meters"));
          if(( dist <= 0) && (where === "start")){_this.entrave.selected.streetsTrack.shift();}
          else if((dist <= 0) && (where === "end")){_this.entrave.selected.streetsTrack.pop();}
          else{
            changedSeg.distance = dist;
            if (where === "start"){_this.entrave.selected.streetsTrack[0] = changedSeg;}
            else{_this.entrave.selected.streetsTrack[_this.entrave.selected.streetsTrack.length - 1] = changedSeg;}
          }
        }
      }
    },

    reversePath(){
      let _this=this;
      if ((_this.entrave.type!='ponctuel') && (_this.entrave.selected.path)  && (_this.entrave.selected.path.length > 0)){
        var swap, sens, start, end;
        var lastPathIdx, lastPointIdx, object;
        
        // reverse path sens
        if(_this.entrave.selected.path.length === 1 && _this.entrave.selected.path[0].segment){
          //console.log("Has segment ", _this.entrave.selected.path[0].segment.geometry.paths);
          _this.entrave.selected.path[0].segment.geometry = _this.reverseSegment(_this.entrave.selected.path[0].segment.geometry);
          _this.entrave.selected.path[0].geometry = _this.reverseSegment(_this.entrave.selected.path[0].geometry);
          //console.log("After Segmet Revrse ", _this.entrave.selected.path[0].segment.geometry.paths);
        }
        swap = _this.entrave.selected.startPoint;
        _this.entrave.selected.startPoint = _this.entrave.selected.endPoint;
        _this.entrave.selected.endPoint = swap;
        _this.entrave.selected.path.reverse();

        
        /*var s = [];
        _this.entrave.selected.path.forEach(function(obj){s.push(obj.geometry.paths);});
        console.log("Before Reverse ", s);
        
        _this.entrave.selected.path.reverse();
        _this.entrave.selected.path.forEach(function(object, i){
          object.geometry = _this.reverseSegment(object.geometry);
          _this.entrave.selected.path[i] = object;
        });
        
        s = [];
        _this.entrave.selected.path.forEach(function(obj){s.push(obj.geometry.paths);});
        console.log("After Reverse ", s);*/
        
        //drawing new arraow
        object       = _this.entrave.selected.path[_this.entrave.selected.path.length - 1];
        lastPathIdx  = object.geometry.paths.length - 1;
        lastPointIdx = object.geometry.paths[lastPathIdx].length - 1;
        
        if (_this.geometryEngine.equals(_this.entrave.selected.endPoint, object.geometry.getPoint(lastPathIdx, lastPointIdx))) {
          sens = "straight";
        }else{
          sens = "reversed";
        }

        [start, end] = _this.getLineEgdes(object.geometry, sens);
        let ang = _this.computeAngle(start, end);
        _this.drawArrowMarker(ang, end);
        _this.entrave.selected.streetsTrack.reverse();
        _this.updateStreetsLabel();
      }
    },
    
    drawArrowMarker(ang, end) {
      let _this = this;
      _this.drawLayer.remove(_this.selectionDirectionArrowMarker);
      _this.selectionDirectionArrowMarker = _this.drawPointMarker(end,
        {
          type  : "simple-marker",
          color : _this.drawColors.drawing,
          angle : ang,
          style : "triangle",
          size  : "15px",
          outlineColor : _this.drawColors.drawing,
          outlineWidth : "0px"
        });
        _this.drawLayer.remove(_this.selectionStartMark);
        _this.selectionStartMark = _this.drawPointMarker(_this.entrave.selected.startPoint, {
          type  : "simple-marker",
          color : _this.drawColors.drawing,
          angle : 0,
          style : "circle",
          size  : "20px",
          outlineColor : _this.drawColors.drawing,
          outlineWidth : "0px"
        });  
    },
    
    inSelectedSegment(object) {
      let _this=this;
      var inSeg;

      return this.entrave.selected.path.some(function(selectedObject){
        inSeg = _this.geometryEngine.equals(object.geometry, selectedObject.geometry);

        return inSeg;
      });
    },
    updateConflictsBuffer(){
      let _this = this;
      if (_this.entrave.selected.path && (_this.entrave.selected.path.length > 0)){
        var geoms, dists = [];
        geoms = _this.entrave.selected.path.map(function(object){
          dists.push(_this.conflictsRadius);
          return object.geometry;
        });
        if (_this.conflictsBuffer){_this.conflictsBuffer=null;}
        if (_this.conflictsGraph){_this.drawLayer.remove(_this.conflictsGraph);}
        var buff =  _this.geometryEngine.geodesicBuffer(geoms, dists, _this.conflictsUnits, true);
        _this.conflictsBuffer = buff[0];
        var g = new _this.Graphic({
          geometry : _this.conflictsBuffer,
          visible : true,
          symbol  : {
            type: "simple-fill",
            //color: [ 51,51, 204, 0.9 ],
            style: "none",
            outline: {  // autocasts as new SimpleLineSymbol()
              color: _this.drawColors.selected,
              width: 2
            }
          }
        });
        _this.drawLayer.add(g);
        _this.conflictsGraph = g;
      }else{console.log("No Path");}
    },
    getProjects(filters, type) {
      let _this=this;
      LoadProjects.loadProjects(filters, function(success, data) {
        if (success) {
          _this.projects=data.filter((e) => { if (e.uuid!==_this.uuid) return e;});
          _this.projects = _this.projects.filter(item => !!item.data.projectType?item.data.projectType.isPublished:true)
          _this.drawLoadingProjects(filters);
          if (type=='conflict') {
            _this.checkConflicts(filters);
          }
          if ((type=='project') && (_this.projects.length==0)) {
            _this.$notify({
              message: _this.$i18n.t('serverReply.errorConflictReadQrchive'),
              timeout: 30000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'info'
            });
          }
          _this.loading=false;
        } else {
          _this.$notify({
            message: _this.$i18n.t('serverReply.errorProject'),
            timeout: 30000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
        }
      });
    },
    drawLoadingProjects(filters) {
      LoadProjects.drawProjects(filters, this.projects, this.projectsLayer, this.Point, this.Polyline, this.Graphic, this.geometryEngine, this.webMercatorUtils, this.geodesicUtils);
    },
    closeAdvancedTab() {
      this.resetAdvancedProjects();
      this.advancedSettings=false;
    },
    onChangeLayer(value){
      this.layer = value;
      this.$emit('onChangeLayer', this.layer);
      this.loadMap();
    },
    resetAdvancedProjects() {
      this.projects=[];
      this.ProjectsConflict=[];
      this.loading=false;
      if (this.projectsLayer) this.projectsLayer.removeAll();
    },
    getAdvancedProjects(params) {
      this.resetAdvancedProjects();
      this.loading=true;
      this.getProjects(params[0], params[1]);
    },
    checkConflicts(filters){
      this.ProjectsConflict=LoadProjects.checkConflicts('entrave', filters, this.uuid, this.projects, this.conflictsBuffer, this.conflictsUnits, this.geometryEngine);
    },
    pushSelectedSegment(object){
      let _this = this;
      var lastPathIdx, lastPointIdx, sName, street;
      lastPathIdx  = object.geometry.paths.length - 1;
      lastPointIdx = object.geometry.paths[lastPathIdx].length - 1;
      if (!_this.entrave.selected.path || !_this.entrave.selected.path.length)
      {
        _this.entrave.selected.path = [object];
        _this.entrave.selected.startPoint = object.geometry.getPoint(0, 0);
        _this.entrave.selected.endPoint   = object.geometry.getPoint(lastPathIdx, lastPointIdx);
        sName = _this.streetName(object);
        _this.entrave.selected.streetsTrack = [{name : sName, distance : _this.geometryEngine.geodesicLength(object.geometry, "meters")}];
        _this.updateStreetsLabel();
      }else{
        // Get the closest path extremity to the selected segment
        /***
         *cs : closest point to path start
         *ce : closest point to path end
         *ds : distance between start point and cs
         *de : distance between end point and ce
        ***/
        var cs, ce, ds, de;
        cs = _this.geometryEngine.nearestCoordinate(object.geometry, _this.entrave.selected.startPoint);
        ce = _this.geometryEngine.nearestCoordinate(object.geometry, _this.entrave.selected.endPoint);
        ds = _this.geometryEngine.distance(_this.entrave.selected.startPoint, cs.coordinate, "meters");
        de = _this.geometryEngine.distance(_this.entrave.selected.endPoint, ce.coordinate, "meters");
        
        var objStartPnt = object.geometry.getPoint(0, 0);
        var objEndPnt   = object.geometry.getPoint(lastPathIdx, lastPointIdx);
        var objSd, objEd;
        if (ds > de){ // end point is the closest
          objSd = _this.geometryEngine.distance(objStartPnt, ce.coordinate, "meters");
          objEd = _this.geometryEngine.distance(objEndPnt,   ce.coordinate, "meters");
          street = _this.entrave.selected.streetsTrack[_this.entrave.selected.streetsTrack.length - 1];
          if ((objSd <= 10) || (objEd <= 10) ){
            if(objSd < objEd){// if end point is closest to segment start point, segment end point will be the new path end point
              _this.entrave.selected.endPoint = objEndPnt;
            }else{// if end point is closest to segment end point, segment start point will be the new path end point
              _this.entrave.selected.endPoint = objStartPnt;
            }
          _this.entrave.selected.streetsTrack = _this.updatedStreetsTrack(street, object, "last");
          }else{console.log("Object not connected to path");}
        }else{ // start point is the closest
          objSd = _this.geometryEngine.distance(objStartPnt, cs.coordinate, "meters");
          objEd = _this.geometryEngine.distance(objEndPnt,   cs.coordinate, "meters");
          street = _this.entrave.selected.streetsTrack[0];
          if ((objSd <= 10) || (objEd <= 10) ){
            if(objSd < objEd){// if start point is closest to segment start point, segment end point will be the new path start point
              _this.entrave.selected.startPoint = objEndPnt;
            }else{// if start point is closest to segment end point, segment start point will be the new path start point
              _this.entrave.selected.startPoint = objStartPnt;
            }
          _this.entrave.selected.streetsTrack = _this.updatedStreetsTrack(street, object, "first");
          }else{console.log("Object not connected to path");}
        }
      }
    
      lastPathIdx  = _this.entrave.selected.path[_this.entrave.selected.path.length - 1].geometry.paths.length - 1;
      lastPointIdx = _this.entrave.selected.path[_this.entrave.selected.path.length - 1].geometry.paths[lastPathIdx].length - 1;
      
      _this.updateConflictsBuffer();
      if (_this.geometryEngine.equals(_this.entrave.selected.endPoint, _this.entrave.selected.path[_this.entrave.selected.path.length - 1].geometry.getPoint(lastPathIdx, lastPointIdx))){
        return [_this.entrave.selected.path[_this.entrave.selected.path.length - 1].geometry, "straight"];
      }else{
        return [_this.entrave.selected.path[_this.entrave.selected.path.length - 1].geometry, "reversed"];
      }
    },
    addSubSegment(object){
      let _this = this;
      let sName, lastPathIdx, lastPointIdx, start, end, sens;

      _this.entrave.type = "linear";
      object.suggest = false;
      _this.entrave.selected.path = [object];
      lastPathIdx  = _this.entrave.selected.path[_this.entrave.selected.path.length - 1].geometry.paths.length - 1;
      lastPointIdx = _this.entrave.selected.path[_this.entrave.selected.path.length - 1].geometry.paths[lastPathIdx].length - 1;
      
      _this.entrave.selected.startPoint = object.geometry.getPoint(0, 0);
      _this.entrave.selected.endPoint   = object.geometry.getPoint(lastPathIdx, lastPointIdx);
      
      sName = _this.streetName(object);
      _this.entrave.selected.streetsTrack = [{name : sName, distance : _this.geometryEngine.geodesicLength(object.geometry, "meters")}];
      _this.updateStreetsLabel();
      
      _this.updateConflictsBuffer();
      
      if (_this.geometryEngine.equals(_this.entrave.selected.endPoint, _this.entrave.selected.path[_this.entrave.selected.path.length - 1].geometry.getPoint(lastPathIdx, lastPointIdx))){
        sens = "straight";
      }else{
        sens = "reversed";
      }
      
      [start, end] = _this.getLineEgdes(object.geometry, sens);
      let ang = _this.computeAngle(start, end);
      _this.drawArrowMarker(ang, end);
    },

    inPossibleSegment(object) {
      let inPoss;
      let _this=this;
      inPoss = _this.possibleSegments.some(function(selected){
        return _this.geometryEngine.equals(object.geometry, selected.object.geometry);
      });
      return inPoss;
    },
    
    pushPossibleSegments(object, graph) {
      let inPoss = false;
      let _this=this;

      inPoss = _this.possibleSegments.some(function(selected){
        return _this.geometryEngine.equals(object.geometry, selected.object.geometry);
      });
      if (!inPoss){
        _this.possibleSegments.push({object : object, graphic : graph});
        return true;
      }
      else{// If in possible segment, must be removed
        _this.deletePossibleSegments(object);
        return false;
      }
    },

    deletePossibleSegments(object) {
      let _this=this;
      let toShift;
      var newPossible = [];
      _this.possibleSegments.forEach(function(selected){
        if (_this.geometryEngine.equals(selected.object.geometry, object.geometry)){
          toShift = selected;
        }else{
          newPossible.push(selected);
        }
      });
      delete _this.possibleSegments;
      _this.possibleSegments = newPossible;
      if (toShift){
        _this.drawLayer.remove(toShift.graphic);
      }
    },
    createPointSearchExtent(event, pixelTolerance) {
      //the lower left corner
      let lowerLeft = this.view.toMap({
        x: event.x - pixelTolerance,
        y: event.y - pixelTolerance
      });
      
      //the upper right corner
      let upperRight = this.view.toMap({
        x: event.x + pixelTolerance,
        y: event.y + pixelTolerance
      });

      //create the search extent
      return new this.Extent({
        xmin: lowerLeft.x,
        ymin: lowerLeft.y,
        xmax: upperRight.x,
        ymax: upperRight.y,
        spatialReference: 102100
      });
    }
  }
  
}
</script>
<style>
 
  div.modal-custom-map div.modal-dialog {
    max-width: 84%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: 920px !important;
  }

  div.modal-custom-map div.modal-body div.map-content {
    min-height: 720px;
  }

  .modal-content .modal-header button.close {
    top: 5px !important;
  }
  .modal-footer {
    position: relative;
  }
  div.modal-footer {
    border-top: 1px solid #b0b0b1 !important;
    padding-top: 16px !important;
  }
  div.right-btn {
    position: absolute;
    right: 30px;
    top: -35px;
  }
  
  ul.esri-search__suggestions-list li,
  ul.esri-search__suggestions-list li strong {
    color: #000 !important;
  }
  #viewDiv {
    padding: 0;
    margin: 0;
    height: 90%;
    width: 100%;
  }
  .select-entrave {
    height: 10%;
    padding: 10px;
    overflow-y: scroll;
  }
  .select-entrave h4{
    color: rgb(82, 95, 127) !important;
  }
  .modal-body {
    padding: 0px;
  }
  .btn-margin-right {
    margin-right: 10px !important;
  }
  .paths-keys {
    font-weight: bold;
  }
  .paths-distance {
    font-weight: 600;
  }
  div.map-content .esri-popup__header-title {
    color: black;
    font-weight: bold;
  }
</style>
